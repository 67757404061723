.checkoutProduct__title1 {
    margin-left: 20px;
    font-size:16px;
    color:teal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.pendingOrdersheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pendingOrdersheader label {
    font-size: 22px;
    color: teal;
}

.pendingorderHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
}

.refrencenu {
    font-size: 20px;
    padding: 4px;
    color: grey;
    margin-left: 10px;
}

.showitembutton {
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 20px;
}

.showitembutton:hover {
    transform: scale(1.14)
}

.pendingorders {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 5px;
    border-radius: 5px;
}