.checkout {
    display: flex;
    padding: 20px;
    background-color: white;
    height: max-content;
}

.checkout__ad {
    width: 100%;
    margin-bottom: 18px;
}

.checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.checkout_right {
    align-items: right;
    margin-right: 2px;
  
}

.checkout1 {
    display: flex;
    padding: 20px;
    background-color: white;
    height: max-content;
    
}
.checkout__title1 {
   
  
    border-bottom: 1px solid lightgray;
}