@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body{
  background-color:rgb(234, 237, 237);
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app{
  width: 100%;
  overflow-x: hidden;
}

header {
    min-height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

header .logo a {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
}

header nav {
    display: flex;
}

nav a:hover {
    color: lightseagreen;
}

nav ul li {
    list-style: none;
    display: inline-block;
}

nav ul li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #555;
    padding: 0 15px;
}

nav ul li a:hover {
    color: lightseagreen;
}

nav .nav-cart {
    cursor: pointer;
    position: relative;
    margin: 10px;
}

nav .nav-cart span {
    position: absolute;
    top: -12px;
    right: -7px;
    background: crimson;
    font-size: 10px;
    color: white;
    padding: 3px 5px;
    border-radius: 50%;
    z-index: -1;
}

header .menu, header .close {
    cursor: pointer;
    display: none;
}

@media (max-width: 650px) {
    header ul {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        background: white;
        z-index: 99;
        opacity: 0.97;
        transition: 0.5s linear;
    }
    header .menu, header .close {
        display: block;
    }
    header .close {
        position: absolute;
        top: 10px;
        right: 20px;
    }
    header ul.toggle {
        left: 0;
    }
  
    header.grid a:hover {
        color: lightseagreen;
    }
}
.header {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    background-color: #131921;
    position: sticky;
    top: 0;
    z-index: 100;
}

.navbarIcon {
    color: white;
    margin-left: 20px;
    align-self: center;
    z-index: 90000000000;
    justify-content: center;
    width: 50px;
}

.topHeader {
    display: flex;
    background-color: #131921;
    align-items: center;
    justify-content: center;
}

.on__header {
    position: sticky;
    top: 0;
    background-color: #131921;
    z-index: 100;
}

.header__logo {
    width: 100px;
    height: 50px;
    object-fit: contain;
    margin: 0 20px;
}

.header__logo1 {
    width: 100px;
    height: 50px;
    object-fit: contain;
    margin: 10px 20px;
    align-items: center;
}

.header__searchIcon {
    padding: 5px;
    margin-top: 3px;
    height: 22px !important;
    background-color: #cd9042;
    align-items: center;
}

.header__search {
    display: flex;
    flex: 1 1;
    align-items: center;
    border-radius: 24px;
}

.header__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    padding: 2px;
}

.header_searchInput {
    height: 12px;
    min-width: 100px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header_nav {
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
}

.header__optionLineOne {
    font-size: 10px;
}

.header__optionLineTwo {
    font-size: 13px;
    font-weight: 800;
}

.header_optionBasket {
    display: flex;
    align-items: center;
    color: white;
}

.header_basketCount {
    margin-left: 10px;
    margin-right: 10px;
}

.header__preliminaries {
    background-color: #131921;
    padding: 10px;
}

.header__button {
    font-size: 13px;
    font-weight: 800;
    color: white;
    background-color: teal;
}

.header__combobox {
    padding: 10px;
    background-color: white;
    color: orange;
}

.header__select {
    padding: 6.8px 14px;
    align-items: center;
    color: #333333;
    background-color: #eeeeee;
    border: 1px solid #dddddd;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 10px;
}

.header__select:focus, .header__select:hover {
    outline: none;
    border: 1px solid #bbbbbb;
}

.header__option .header__optionLineTwo:hover {
    color: yellow;
}

.header_nav .header__option:hover {
    border: 1px solid #bbbbbb;
}

.menu-bar {
    color: white;
    margin-left: 10px;
    font-size: 15px;
}

.titlep {
    font-weight: bold;
    margin-left: 4px;
}

.subdisplay {
    display: flex;
    flex-direction: column;
}

.sublabeldisplay {
    color: teal;
    cursor: pointer;
}

.sublabeldisplay:hover {
    color: red;
}

.gorgGroupName {
    cursor: pointer;
}

.gorgGroupName :hover {
    color: red;
}

.nav-menu-items {
    display: flex;
    flex-wrap: wrap;
}

.labelHeader {
    padding: 5px 0 6px;
    cursor: pointer;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 3px;
    text-decoration: none;
    font-size: 15px;
    font-family: 'Arial', sans-serif;
}

.labelHeader:hover {
    border: 2px solid white;
}
.header_btm{
    height:40px;
}
.navbarside {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 1000000000;
}

.navbar_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header_part {
    display: flex;
    top: 0;
    flex-direction: row;
    position: sticky;
    width: 350px;
    justify-content: space-between;
    background: red;
}

.header_partlable {
    font-weight: bold;
    padding: 10px;
    font-size: 15px;
    margin: 2px;
    color: white;
}

.header_avatar {
    margin-left: 20px;
}

.x_icon {
    width: 65px;
    height: 65px;
    color: white;
    font-weight: bold;
    font-size: 30px;
}

.navbar_right {
    justify-content: center;
    align-items: top;
    width: 100%;
}

.cat_below_header {
    width: 100%;
    height: 86vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    padding-bottom: 40px;
}

.cat_below_header .label {
    font-size: 14px;
}

.cat_item {
    margin-left: 20px;
    display: flex;
    margin-top: 2px;
    border-radius: 10px;
    padding: 9px;
    width: 80%;
    justify-content: space-between;
}

.cat_item:hover {
    background-color: #EBEDEF;
    cursor: pointer;
}

.cat_back {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 10px;
    width: 100%;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.cat_back label {
    margin-left: 10px;
}

.cat_back:hover {
    background-color: #EBEDEF;
}
.home__image{
    width:100%;
    z-index: -1;
    margin-bottom: -150px;
    -webkit-mask-image: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,0));
            mask-image: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,0));
} 


#product {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
.productList1{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;  
}
.card {
    min-width: 150px;
    min-height: 200px;
    overflow: hidden;
    padding: 10px;
    margin: 10px;
    transition: 0.5s linear;
}

.card:hover {
    box-shadow: none;
}

.card img {
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.card h6 {
    text-transform: uppercase;
    margin: 10px 0;
    width: 200px;
}

.card h6 a {
    text-decoration: none;
    color: #333;
}

.card h3 a:hover {
    color: crimson;
}

.card span {
    color: crimson;
}

.card p {
    margin: 10px 0;
}

.card button {
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    
    letter-spacing: 2px;
    margin: 15px 0;
    border-radius: 19px;
}

.search-loading {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.show {
    display: inline-block;
    width: 150px;
    background-color: transparent;
}

.hide {
    display: none;
}

.home {
    display: flex;
    justify-content: center;
    margin-right: auto;
}
.slidere{
    width: 100%;
}
.home__row {
    display: flex;
    z-index: 1;
    margin-right: 2px;
}

.home__image {
    width: 100%;
    z-index: -1;
    margin-bottom: -300px;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}



.product_p_img {
    max-height: 150px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 15px;
}

.product__info {
    margin-bottom: 15px;
}


.product_rating {
    display: flex;
}

.each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
}

.paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 10px;
    border: 1px solid teal;
    cursor: pointer;
}

.paginationBttns a:hover {
    color: white;
    background-color:teal;
}

.paginationActive a {
    color: white;
    background-color: teal;
}
.product_p{
   border-radius: 1px;
}


.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin-bottom: -390px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.sliderMobile {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin-bottom: -550px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.image {
    width: 100%;
    z-index: -1;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}


.right-arrow {
    position: absolute;
    right: 10px;
    margin-top: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 32;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}
.right-arrowMobile {
    position: absolute;
    right: 10px;
    margin-top: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}

.left-arrow {
    position: absolute;
    margin-top: 100px;
    left: 1px;
    font-size: 3rem;
    color: #000;
    z-index: 32;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}
.left-arrowMobile {
    position: absolute;
    margin-top: 100px;
    left: 1px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}
       #product {
           width: 100%;
           display: flex;
           justify-content: left;
           flex-wrap: wrap;
       }

       .card {
           min-width: 150px;
           min-height: 200px;
           overflow: hidden;
           padding: 10px;
           margin: 10px;
           transition: 0.5s linear;
       }

       .card:hover {
           box-shadow: none;
       }

       .card img {
           max-height: 300px;
           display: block;
           object-fit: cover;
       }

       .card h6 {
           text-transform: uppercase;
           margin: 10px 0;
           width: 200px;
       }

       .card h6 a {
           text-decoration: none;
           color: #333;
       }

       .card h3 a:hover {
           color: crimson;
       }

       .card span {
           color: crimson;
       }

       .card p {
           margin: 10px 0;
       }

       .card button {
           border: none;
           outline: none;
           background: #333;
           color: white;
           width: 100%;
           height: 40px;
           display: block;
           cursor: pointer;
           text-transform: uppercase;
           letter-spacing: 2px;
           margin: 15px 0;
           border-radius: 19px;
       }

       .search-loading {
           position: absolute;
           left: 0;
           right: 0;
           margin: auto;
       }

       .show {
           display: inline-block;
       }

       .hide {
           display: none;
       }

       .home {
           display: flex;
           justify-content: center;
           margin-left: auto;
           margin-right: auto;
       }

       .slidere {
           width: 100%;
       }

       .home__row {
           display: flex;
           z-index: 1;
           margin-left: 2px;
           margin-right: 2px;
       }

       .home__image {
           width: 100%;
           z-index: -1;
           margin-bottom: -300px;
           -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                   mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
       }

       .product_p {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           margin: 2px;
           padding: 10px;
           background-color: white;
           z-index: 1;
       }

       .product_p_imgp {
           max-height: 250px;
           width: 100%;
           max-width: 270px;
           object-fit: contain;
       }

       .product__infop {
           padding: 15px;
           border-radius: 15px;
           position: relative;
       }

       .product_p_btnp {
           border: 1px solid;
           margin-top: 10px;
           /* border-color: #a88734 #9c7e31 #846a29; */
           color: #111;
       }

       .product_ratingp {
           display: flex;
       }

       .product_p_btnp:hover {
           cursor: pointer;
       }

       .addButton {
           align-items: center;
           justify-content: center;
           display: flex;
           flex-direction: row;
           cursor: pointer;
           outline: none;
           border: none
       }

       .addButton:hover {
           -webkit-transform: scale(1.05);
                   transform: scale(1.05);
           outline: none;
       }

       .product_p:hover {
           cursor: pointer;
       }

       .promotionsdiv {
           width: 58px;
           position: absolute;
           top: 0px;
           right: 0px;
           display:flex;
           flex-direction: row;
           justify-content:center;
           border-radius: 8px;
           z-index: 100;
       }
      .promotionsdivl{
        width: 100px;
        position: absolute;
        top: 0px;
        left: 0px;
        display:flex;
        flex-direction: row;
        justify-content:center;
        border-radius: 8px;
        z-index: 100;
      }
       .promolbl{
           color:grey;
           text-decoration: line-through;
       }
.carousel_header {
    width: 100%;
    height: 200px;
    background: #333;
    color: #fff;
    font-size: 30px;
    margin: 0 20;
    display: flex;
  
    justify-content: center;
    align-items: center;
}

.item_header {
    margin-left: 20px;
    color: grey;
    margin-right: 10px;
}

.itemGroupMain {
    background: white;
    border-radius: 14px;
    margin: 10px;
   
}

.product_p_c {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1px;
    margin:20px;
    background-color: white;
    z-index: 1;
}

.group_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group_header a {
    color: blue;
    text-decoration: none;
    margin-right: 20px;
}

.group_header a:hover {
    color: teal;
   
}

.caures_card {
    display: flex;
    flex-direction: column;
    position: relative;

}

.caures_img {
    height: 200px;
    width: 250px;
    object-fit: contain;
}

.divcaures {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.lblproductname {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont;
    font-size: 16px;
    color: #282828;
    padding: 8px 8px 4px;
}

.lblamount {
    padding: 8px 4px 0px;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont;
    font-size: 20px;
    color: #282828;
}

.promotionsdiv {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    z-index: 100;
}

.promolbl {
    color: grey;
    text-decoration: line-through;
}
.namenprocediv{
    display: flex;
    flex-direction:column;
    justify-content: center;
    width:100%;
    margin-left: 20px;
}

.details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 0;
}

.productWhole {
    width: 100%;
    background: white;
}

.details .big-img {
    max-width: 500px;
    min-width: 290px;
    overflow: hidden;
    margin: 25px;
}

.big-img img {
    width: 100%;
    height: 100%;
    max-height: 450px;
    display: block;
    object-fit: contain;
}

.about_title {
    font-size: 24px;
    font-style: bold;
}

.details .box {
    max-width: 500px;
    min-width: 290px;
    margin: 25px;
}

.box .row {
    display: flex;
    justify-content: space-between;
}
.box{
    position: relative;
}

.box .row span {
    text-transform: uppercase;
    color: #0F1111;
    font-size: 24px;
    ;
    font-family: "Amazon Ember", "Arial", sans-serif;
}

.box .colors button {
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
}

.box p {
    line-height: 1.5;
    margin: 15px 0;
}

.thumb {
    width: 100%;
    height: 100px;
    display: flex;
    cursor: pointer;
    margin: 10px 0;
}

.thumb img {
    width: 65px;
    height: 70%;
    display: contain;
    object-fit: contain;
    border: 1px solid #ddd;
    margin-right: 5px;
    opacity: 0.7;
    border-radius: 5px;
}

.thumb img.active {
    opacity: 1;
    border: 1px solid lightseagreen;
}

.box .cart {
    background: #333;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 25px;
    margin-top: 15px;
}

.search-loading {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.show {
    display: inline-block;
}

.hide {
    display: none;
}

@media (max-width: 500px) {
    .thumb {
        height: 50px;
    }
    .thumb img {
        width: 50px;
    }
}

.item_group {
    font-size: 14px;
    padding: 2px 5px 2px 6px;
    font-family: "Amazon Ember", "Arial", sans-serif;
}

.item_grouplabel {
    font-size: 24px;
    font-weight: bold;
    padding: 2px 5px 2px 6px;
    font-family: "Amazon Ember", "Arial", sans-serif;
}

.groupcodelabel {
    font-size: 10px;
    font-weight: bold;
    padding: 2px 5px 2px 6px;
    font-family: "Amazon Ember", "Arial", sans-serif;
    margin-left: 5px;
}
.promotionsdiv {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display:flex;
    flex-direction: row;
    justify-content:center;
    border-radius: 8px;
    z-index: 100;
}
.promolbl{
    color:grey;
    text-decoration: line-through;
}
.pricespans{
    display:flex;
    flex-direction:column;

}
.Categories {
    background-color: rgb(234, 237, 237);
}

.categories_title {
    margin-right: 10px;
    position: sticky;
}

#product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    justify-content:center;
}

.Categories_left {
    width: 100%;
}

.Categories_left1 {
    margin-left: 250px;
    width: 100%;
}

.each_sub_c {
    cursor: pointer;
    color: teal;
    font-size: 13px;
    margin-left: 6px;
}

.card {
    min-width: 150px;
    min-height: 200px;
    overflow: hidden;
    padding: 10px;
    margin: 10px;
    transition: 0.5s linear;
}

.card:hover {
    box-shadow: none;
}

.card img {
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.card h6 {
    text-transform: uppercase;
    margin: 10px 0;
    width: 200px;
}

.sub {
    display: flex;
    object-fit: contain;
    position: relative;
}

.sub p {
    margin-left: 10px;
    margin-bottom: 10px;
}

.main_categories label:hover {
    color: red;
}

.sub label:hover {
    color: red;
}

.card h6 a {
    text-decoration: none;
    color: #333;
}

.card h3 a:hover {
    color: crimson;
}

.card span {
    color: crimson;
}

.card p {
    margin: 10px 0;
}

.card button {
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
    border-radius: 19px;
}

.search-loading {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.show {
    display: inline-block;
}

.hide {
    display: none;
}

.home {
    display: flex;
}

.home__row {
    display: flex;
    z-index: 1;
    margin-left: 2px;
    margin-right: 2px;
}

.home__image {
    width: 100%;
    z-index: -1;
    margin-bottom: -300px;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.product_ps {
    display: flex;
    flex-direction: column;
    margin: 2px;
    padding: 10px;
    background-color: white;
}

.product_p_img {
    max-height: 150px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 15px;
}

.product__info {
    margin-bottom: 15px;
}

.product_p_btn {
    background: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    width: 100%;
}

.product_rating {
    display: flex;
}

.each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.item_group_name {
    justify-content: center;
    align-items: left;
    font-size: 40px;
    font-style: bold;
    margin-left: 20px;
}

.main_categories {
    padding: 10px;
}

.main_category_header {
    font-size: 20px;
    margin: 6px;
    color: teal;
    cursor: pointer;
}

.main_category_header:hover {
    color: red;
}

.popupcategories {
    margin-left: 20px;
}

.ll {
    font-weight: bold;
}

.categoriesinput {
    width: 80%;
    padding: 2px;
    position: sticky;
    top: 0;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
}

.buttonCategories {
    border-radius: 4px;
    background-color: teal;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    width: 98%;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

.buttonCategories span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.buttonCategories span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.buttonCategories:hover span {
    padding-right: 25px;
}

.buttonCategories:hover span:after {
    opacity: 1;
    right: 0;
}
@media screen and (max-width:756px) {
    .item_group_name{
        font-size: 18px;
    }
    
}
.itemGroups {
    display: column;
    border-right: 1px solid lightgray;
    margin-left: 2px;
    padding: 3px;
   
}

.each_group {
    color:rgb(1, 1, 12);
    font-size: 18px;
}

.each_group a:hover {
    color: teal;
    cursor: pointer;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    display: column;
}

.each_sub {
    margin-left: 10px;
    font-size: 13px;
    font-style: bold;
    color:gray;
}
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 110vh;
    top: 0;
    left: 0;
    z-index: 1;
   align-items:  left;
}

.box {
    position: left;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(110vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(110vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}
.navbar {
    display: flex;
    align-items: center;
    margin: 10px;
    z-index: 200;
    background: white;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
}

.menubar {
    font-size: 20px;
}
.nav-menu-items{
    background: white;
}
.nav-menu {
    background: #00000050;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 150ms;
    z-index: 200;
}

.nav-menu.active {
    left: 0;
    transition: 150ms;
}

.nav-text {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: 10px;
}

.nav-text a {
    text-decoration: none;
    font-size: 15px;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: white;
}

.nav-text a:hover {
    background-color: teal;
}

.navbar-toggle {
    text-decoration: none;
    display: flex;
    background: white;
}

.closeIcon {
    color: red;
    font-size: 30px;
    margin-left: 70px;
}
.checkout {
    display: flex;
    padding: 20px;
    background-color: white;
    height: -webkit-max-content;
    height: max-content;
}

.checkout__ad {
    width: 100%;
    margin-bottom: 18px;
}

.checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.checkout_right {
    align-items: right;
    margin-right: 2px;
  
}

.checkout1 {
    display: flex;
    padding: 20px;
    background-color: white;
    height: -webkit-max-content;
    height: max-content;
    
}
.checkout__title1 {
   
  
    border-bottom: 1px solid lightgray;
}
.subtotal{
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    width:300px;
    height:100px;
    padding:20px;
    background-color:#f3f3f3 ;
    border:1px solid #dddddd;
    border-radius: 3px;
}
.subtotal_gift{
    display:flex;
    align-items: center;
}
.subtotal__gift>input{
    margin-right:5px;

}
.subtotal>button{
    background: #f0c14b;
    border-radius: 2px;
    width:100%;
    height:30px;
    border:1px solid;
    margin-top:10px;
    border-color:#a88734 #9c7e31 #846a29;
    color:#111;
}
.header__preliminaries1{
    display: flex;
   

}

.shipping__loc{
    font-style: bold;
    color: teal;
    margin: 2px;
}
.checkoutProduct {
    display: flex;
    padding: 10px;
    border: 1px solid lightgray;
    margin: 20px;
    border-radius: 10px;
}

.checkoutProduct__info {
    padding-left: 20px;
    display: flex;
    flex-direction:column;
    justify-content:space-evenly;
}

.checkoutProduct__info>button {
    background: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111
}

.checkoutproduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px
}

.checkoutProduct__title {
  font-family: Roboto, Helvetica, sans-serif;
}

.count {
    width: 30px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #555;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.checkout__input {
    width: 70px;
    text-align: center;
}
.checkoutProduct__price{
    font-family: Roboto, Helvetica, sans-serif; 
    font-size: 18px;
    color:grey;
}
.login {
    display: flex;
    height: 100vh;
    background-color: white;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
}
.login__container{
    width:300px;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    display:flex;
    flex-direction: column;
    border-radius: 5px;
    border:1px solid lightgray;
    padding:20px
}
.login__container >h1{
    font-weight: 500;
}
.login__container form >input{
    height :30px;
    margin-bottom: 10px;
    width:98%;
    background-color:white;
}
.login__container >p{
    margin-top:15px;
    font-size:12px;
}
.login__signButton{
    background:#f0c14b;
    border-radius: 2px;
    width:100%;
    height:30px;
    border:1px solid;
    margin-top:10px;
    border-color :#a88734 #9c7e31 #846a29;
}
.login__registerButton{
     border-radius: 2px;
     width: 100%;
     height:30px;
     border:ipx solid;
     margin-top:10px;
     border-color:darkgray;
}


.login3 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.login__container2 {
    width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid lightgray;
}

.login__container2>h1 {
    font-weight: 400;
}

.smallabel {
    height: 10px;
    font-size: 13px;
}

.login__container2 form>input {
    width: 98%;
    background-color: white;
    height: 30px;
    border: 1px solid lightgray;
}

.login__container2>p {
    font-size: 12px;
}

.login__signUpButton {
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
}

.login__registerButton {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: ipx solid;
    margin-top: 10px;
    border-color: darkgray;
}

.field {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.signinlink {
    margin-left: 10px;
    text-decoration: none;

}

.signinlink:hover {
    background-color: rgba(255, 255, 255, 0.45);
}

.field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
    padding: 24px 16px 8px 16px;
}

.field.active input+label {
    top: 4px;
    opacity: 1;
    color: #512da8;
}

.field.locked {
    pointer-events: none;
}

.field input {
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input+label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
}

.field input+label.error {
    color: #ec392f;
}

.field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #e0e0e0;
    opacity: 1;
    pointer-events: none;
}
.checkoutProduct__title1 {
    margin-left: 20px;
    font-size:16px;
    color:teal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.pendingOrdersheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pendingOrdersheader label {
    font-size: 22px;
    color: teal;
}

.pendingorderHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
}

.refrencenu {
    font-size: 20px;
    padding: 4px;
    color: grey;
    margin-left: 10px;
}

.showitembutton {
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 20px;
}

.showitembutton:hover {
    -webkit-transform: scale(1.14);
            transform: scale(1.14)
}

.pendingorders {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 5px;
    border-radius: 5px;
}
.showitems{
    display: flex;
    width:100%;
    flex-direction: column;
    background-color: white;
}
.showitemstop{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding:10px;
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 10px;
}
.arrowbackshow{
    align-items:center;
    margin-right: 20px;
    font-size: 30px;
}
.shitmlbltop{
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 20px;
}
.checkout__tit{
    margin-left: 10px;
    font-family: Roboto, Helvetica, sans-serif;
    padding:4px;
}
.itemsorder{
    margin:4px;
    display: flex;
}
.itemsorderlbl{
    margin-top: 10px;
    font-family: Roboto, Helvetica, sans-serif;  
    padding: 10px;
    font-size: 20px;
}

.dFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.lb {
    color: black;
}

.card_img1 img {
    width: 200px;
    height: 100px;
    object-fit: contain;
}

.card_header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px;
}

.card_header h2 {
    font-size: 15px;
    
}

.card_header p {
    font-size: 15px;
}

.promotionsdiv {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    z-index: 1;
}

.promolbl {
    color: grey;
    text-decoration: line-through;
}

.btncardadd {
    outline: none;
    border: 1px solid lightgray;
    background-color: transparent;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: teal;
    margin:2px;
}

.btncardadd:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05)
}

.addToCartandView {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media screen and (max-width:756px) {
.card{
    width: 1px;
    height: 30px;
}
.btncardadd {
    outline: none;
    border: 1px solid lightgray;
    background-color: transparent;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: teal;
    margin:0px;
    font-size: 12px;
}

.card_header h2 {
    font-size: 13px;
    
}
}
.aboutus {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
}

.aboutusimgbanner {
    object-fit: contain;
    height: 60px;
}
.aboutuscompmain {
    display: flex;
    justify-content: center;
    background:white;
    width: 70%;
    margin: 10px;
}

.rightpart {
    width: 100%;
    padding: 10px;
}

.leftpart {
    width: 50%;
    padding: 20px;
}

/* .leftpart p {
    font: normal normal 12px/1.4 "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 19px;
    width:50%;
    align-items:center;
    
} */
.ourimg{
    width:100%;
    object-fit: contain;

}
.tt{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footerAd {
    display: flex;
    opacity:1;
    justify-content: center;
    align-items: top;
    background-color: #131921;
    flex-direction: space-between;
    margin: 20px;
    border-radius: 17px;
}

.footerAboutUs {
    display: flex;
    justify-content: top;
    align-items: top;
    flex-direction: column;
    margin: 20px;
    width:33.3%;
}
.footerAboutUs span{
    color:teal;
}
.fbicon{
    color:blue;
    margin-right: 10px;
}
.twicon{
    color:lightblue;
    margin-right: 10px;
}
.yoicon{
    color:red;
    margin-right: 10px;
}
.isicon{
    color :purple;
    margin-right: 10px;
}
.footerAboutUs h5 {
    margin-left: 50px;
    margin-right: 50px;
    color: white;
}

.footerAboutUs a {
    color: white;
    font-weight: italic;
    color: teal;
    text-decoration: none;
}
.footerAboutUs label{
    font-weight: bold; 
    color: white;
}

.footerAboutUs a:hover {
    color: blue;
}
.footerAboutUsImg{
    width:20px;
    height:20px;
  
    margin-right: 10px;
  
}


body,
html {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}

.footer-gray {
  background-color: #efefef;
  width: 100%;
}

.footer-custom {
  color: #888;
  font: normal normal 12px/1.4 "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  max-width: 1008px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 24px;
}

.footer-custom:after {
  display: table;
  clear: both;
  content: "";
}

.footer-lists:after {
  display: table;
  clear: both;
  content: "";
}

.ftr-hdr {
  color: #000;
  font: 22px/1.4 "BebasNeueRegular", BebasNeue, "Bebas Neue", Arial, sans-serif;
  margin: 1em 0 0;
}

@media only screen and (min-width: 768px) {
  .ftr-hdr {
    font-size: 18px;
  }
}

.footer-list-wrap {
  width: 50%;
  float: left;
  margin: 10px;
  box-sizing: border-box;
}

@media only screen and (min-width: 568px) {
  .footer-list-wrap {
    width: 33.3333%;
  }
}

@media only screen and (min-width: 768px) {
  .footer-list-wrap {
    width: 25%;
  }
}

.ftr-links-sub {
  padding: 0;
  margin: 0;
}

.ftr-links-sub:after {
  display: table;
  clear: both;
  content: "";
}

.ftr-links-sub li {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 3px 0;
  color: #888;
  font: normal normal 12px "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  /*width: 150px;*/
}

.footer-custom a,
.footer-custom a:link,
.footer-custom a:visited,
.ftr-links-sub li .link {
  text-decoration: none;
  padding: 5px 0;
  display: block;
}

.footer-custom .footer-legal a {
  display: inline;
}

.footer-custom a:hover,
.footer-custom a:active,
.ftr-links-sub li .link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .footer-custom a,
  .footer-custom a:link,
  .footer-custom a:visited,
  .ftr-links-sub li .link {
    padding: 0;
  }
}
/* BEGIN EMAIL CAPTURE STYLES*/

.footer-email {
  text-align: center;
}

#ftrEmailForm {
  height: 44px;
}

#ftrEmailForm .error {
  display: none;
  color: red;
  text-align: left;
}

#ftrEmailForm #ftrEmailInput {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  color: #888;
  float: left;
  font: normal normal 14px/1.4 "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 5px;
  width: 70%;
  height: 100%;
}

#ftrEmailForm #ftrEmailInput:hover {
  border: 1px solid #888;
}

#ftrEmailForm #ftrEmailInput:focus {
  border: 1px solid #ef9223;
  outline: #ef9223 auto 5px;
}

#ftrEmailForm .button {
  width: 30%;
  height: 100%;
  padding: 5px;
  float: left;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  color: #000;
  font: normal bold 18px/1 BebasFamily, BebasNeueRegular, "Bebas Neue",
    Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: #fff;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: linear-gradient(to bottom, white 0, #e1e1e1 100%);
  cursor: pointer;
  vertical-align: middle;
  outline: none;
}

#ftrEmailForm .button:hover,
#ftrEmailForm .button:active {
  background: black;
  color: #fff;
  outline: none;
}

.footer-social {
  text-align: center;
}

.footer-social ul {
  padding: 0;
  display: inline-block;
  list-style-type: none;
}

.footer-social ul:after {
  display: table;
  clear: both;
  content: "";
}

.footer-social li {
  float: left;
  margin: 0 15px 0 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .footer-lists {
    width: 100%;
  }
  .footer-email {
    width: 50%;
    float: left;
    text-align: left;
  }
  .footer-social {
    width: 45%;
    margin-left: 5%;
    float: left;
    text-align: left;
  }
}

@media only screen and (min-width: 1008px) {
  .footer-lists {
    width: 66.6666%;
    float: left;
  }
  .footer-email {
    width: 33.3333%;
  }
  .footer-social {
    width: 33.3333%;
    float: right;
    margin-left: 0;
  }
}

.footer-legal {
  padding: 15px 0 0;
  clear: left;
}

.footer-payment {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .footer-legal {
    width: 66.6666%;
    float: left;
  }
  .footer-payment {
    width: 33.3333%;
    float: left;
  }
}

@media only screen and (min-width: 1008px) {
  .footer-payment {
    text-align: left;
  }
}

.footer-payment ul {
  padding: 0;
  display: inline-block;
  list-style-type: none;
}

.footer-payment ul li {
  display: inline-block;
  margin: 0 6px;
}

@media only screen and (min-width: 1008px) {
  .footer-payment ul li.ftr-stella {
    margin-left: 0;
  }
}

.ftr-bbb span,
.ftr-stella span {
  background-image: url("http://cache1.artprintimages.com/images/jump_pages/rebrand2/images/subnav2.png");
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  margin: 0;
  padding: 0;
}

.ftr-bbb span {
  background-position: -339px -8px;
  width: 96px;
}

.ftr-stella span {
  background-position: -339px -107px;
  width: 57px;
}
.floating-whatsapp{
  z-index: 1000000000;
}

.categoriesAd {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 2px;
    z-index: 10000;
}

.classLeft {
    width: 100%;
    height: 100vh;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
}

.cat-layout {
    position: sticky;
    background:  white;
    top: 0;
    z-index: 10000;
}



.cat-search {
    margin-left: 2px;
    width: 95%;
    border-radius: 10px;
    padding: 6px;
    border-color: white;
    margin-bottom: 20px;
}

.cat-search:focus {
    border-color: white;
    outline: none;
}

.accordions {
    width: 99%;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
}

.accordionItem {
    width: 99%;
}
.search-results {
    background: #F2F4F4;
    padding: 10px;
    width: 100%;
}

.headerdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #85929E;
    padding: 10px;
}

.items-div {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 12px;
    margin-right: 100px;
    margin-left: 10px;
    padding: 10px;
}

.search_page {
    display: flex;
    flex-direction: row;
}

.categoryPanel {
    width: 400px;
    min-width: 300px;
    background-color: white;
    border-radius: 12px;
    margin-left: 30px;
    margin-top: 10px;
    margin-right: 10px;
}

.categoryPanelHeader {
    font-size: 18px;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont;
    margin-top: 40px;
    padding: 8px 16px;
    color: #282828;
}

.panelItems {
    display: flex;
    flex-direction: column;
}

.panelItemsLabel {
    padding: 8px 32px;
    font-size: 15px;
}

.divCatItm {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
}

.divCatItm:hover {
    background-color: #F2F4F4;
}

.pag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
@media screen  and (max-width:756px) {
    .categoryPanel{
        display: none;
        margin: 1px;
    }
    .items-div {
        margin-top: 10px;
        width: 100%;
        background-color: white;
        border-radius: 12px;
        margin-right: 5px;
        margin-left: 5px;
        padding: 1px;
    }
    .divCatItm {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 1px;
    }
    
}
.each-search-result {
    display: flex;
    border-bottom: 2px solid #F2F4F4;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    margin: 20px;
    position: relative;
}
.each-search-result:hover{
    -webkit-transform:scale(1.04);
            transform:scale(1.04)
}
.each-search-resultP1 {
    display: flex;
    flex-direction: row;
}

.image1 {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.product-details {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.lblItemName {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}

.lblItemCode {
    color: blue;
    font-size: 16px;
}

.lblPrice {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice small {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice strong {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}

.product-pricing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.btnAddtocart{
    color:#FFFF;
    border:none;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 70px;
    padding-left: 70px;
    min-width: 100px;
    font-size: 18px;

}
.pricesdiv{
    display: flex;
    flex-direction:column;
}
@media screen and (max-width:756px){

   .each-search-result {
    display: flex;
    border-bottom: 2px solid #F2F4F4;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px;
    margin: 3px;
    position: relative;
} 
.btnAddtocart{
    color:#FFFF;
    border:none;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 3px;
    padding-left: 3px;
    min-width: 100%;
    font-size: 13px;

}
.lblPrice {
    margin-top: 10px;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice small {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice strong {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.product-details {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.lblItemName {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.image1 {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.product-details {
    display: flex;
    flex-direction: column;
    margin: 4px;
}
}
.product_categories {
    display: flex;
    flex-direction: row;
    align-items: top;
    width: 100%;
    margin-top: 30px;
    background-color: #F4F6F6;
}

.mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #85929E;
    padding: 10px;
    background-color: #F4F6F6;
}

.product-categoriesLeft {
    width: 200px;
    border-right: 2px solid #CBCFD3;
    padding: 2px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.product-categoriesRight {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.product-the-header {
    width: 100%;
    padding: 5px;
}

.product-category-group {
    color: #0C2134;
    font-size: 18px;
    font-family: 'Varela Round';
}

.divLine {
    width: 100%;
    margin-bottom: 5px;
}

.product-category-group:hover {
    color: #CD6155;
    cursor: pointer;
}

.product-category-subgrouplabel {
    margin-left: 30px;
}

.productC {
    background-color: #F4F6F6;
}

.product-category-subgroup {
    display: flex;
    flex-direction: column;
}

.product-category-subgrouplabel:hover {
    color: #CD6155;
    cursor: pointer;
}

.dFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    background-color: #F4F6F6;
}

.collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}

.catediv {
    margin: 5px;
}

.product_categoriesName {
    align-items: center;
    width: 100%;
    justify-content: center;
}

.product_categoriesName label {
    align-items: center;
    justify-content: center;
    color: teal;
    font-size: 27px;
    margin-left: 100px;
}

.gdivh {
    color: teal;
    font-size: 20px;
}

.dropdownButton {
    border-radius: 5px;
    margin: 2px;
    outline: none;
    cursor: pointer;
    border: 1px solid #CBCFD3;
}

.dropdownButton:hover {
    color: blue;
}

.dropdownButtondiv {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdownButtondiv label:hover {
    color: white;
    background-color: blue;
}

.drowpdown {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #F4F6F6;
}

.dropdownlabel {
    font-size: 12px;
    font-weight: bold;
    color: #555555;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.dropdownbuttonItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2000000;
    background: white;
    padding: 12px;
}

.dropdownButton:hover {
    background-color: #F4F6F6;
}

.sub_item.hover {
    background-color: red;
}

.sub_item {
    text-decoration: none;
    color: #555555;
    width: 100%;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    padding: 7px 12px;
    font-size: 12px;
    border-bottom: 1px solid #CBCFD3;
}

.category_brand {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.head_brand_category {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.hbc {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding:10px;
    border-radius: 6px;
  

}

.inputs {
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.input_header {
    width: 200px;
    padding: 2px;
    margin-left: 5px;
    border-radius: 5px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.category_color {
    margin-top: 10px;
    width: 85%;
}

.dropdownbars {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.inputCheckbox {
    font-size: 13px;
    cursor: pointer;
}
.inputCheckbox:hover{
    color:teal;
}
.divPagenate{
    display: flex;
    flex-direction:row-reverse;
    justify-content:right;
    width: 100%;
    
    
}
.rwidget{
    display: flex;
    flex-direction:row-reverse;
    justify-content:right;
}
.shoppingmainpage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
}

.shoppingdetails {
    margin: 20px;
    padding:20px;
    background-color: white;
    width:100%;
    min-width: 800px;
}

.cartdetails {
    min-width: 300px;
  
 
  
}
.shoppingcartpart{
    height: -webkit-max-content;
    height: max-content;
    margin: 10px;
    padding:10px;
    background-color: white;
}

.shoppingheaderlabel {
    font-size: 28px;
    color:#0F1111;
    font-family: Arial, Helvetica, sans-serif;
}
.hpartprice{
    align-items:left;
    display:flex;
    flex-direction:row-reverse;
    border-bottom: 1px solid  #F2F4F4 ;
}
.lprc{
    color:#565959;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 4px;
}
.subtotalspan{
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}
.check_btn{
    padding:10px;
    margin-top:10px;
    width:100%;
    border:none;
    outline:none;
    border-radius:10px;
    margin-bottom: 20px;
}
.check_btn:hover{
    border:none;
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05)
}
.botmpart{
    display: flex;
    flex-direction: row-reverse;
}
.otheritemspart{
    height: -webkit-max-content;
    height: max-content;
    margin: 10px;
    padding:10px;
    background-color: white;
    border-radius: 12px;
}
.otherpartlblheader{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #0f1111;
}
.maincartdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
}

.prdimgCss {
    object-fit: contain;
    width: 250px;
    height: 300px;
}

.imganddetails {
    display: flex;
    flex-direction: row;
}

.detailspro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px
}

.blayout {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.divQuantity {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid lightgray;
}

.divDelete {
    padding-right: 10px;
    margin-right: 10px;
}

.lbldelete {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

.lbldelete:hover {
    color: teal;
    cursor: pointer;
    text-decoration: underline;
}

.lblh {
    margin-top: 20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.lblh:hover {
    color: teal;
    cursor: pointer;
    text-decoration: underline;
}

.llblprice {
    margin-top: 30px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.checkoutqtinp {
    outline: none;
    padding: 4px;
    align-items: center;
    width: 80px;
    border-radius: 8px;
    border: 1px solid lightgray;
    font-size: 14px;
}

.itemprelisquits {
    display: flex;
    flex-direction: column;
}

.rediced {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.lvlh {
    font-size: 13px;
    color: teal;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.lvlhsub {
    font-size: 13px;
    color: teal;
    font-family: Arial, Helvetica, sans-serif;
}

.divsright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.amountvalue {
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.lblamountvalue{
    font-size:24px;
    color:teal;
}
.cartsimilarmain {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-bottom: 1px solid lightgray;
}

.cartsimilarimg {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.itemsdivcart {
    margin: 8px;
    display: flex;
    flex-direction: column;
   
}

.cartsimilarname {
    font-size: arial, Helvetica, sans-serif;
    font-size: 14px;
    color: teal;
}

.cartsimilarprice {
    font-size: arial, Helvetica, sans-serif;
    font-size: 16px;
    color: rgb(48, 43, 43);
    margin-top: 4px;
    margin-bottom: 4px;
}

.cartsimalerbutton {
    outline: none;
    border-radius: 4px;
    border: none;
    padding:6px;
    cursor: pointer;
}
.cartsimalerbutton:hover{
    -webkit-transform: scale(1.06);
            transform: scale(1.06)
}
.CompleteMainPage {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.checkoutmain {
    width: 100%;
    margin-left: 200px;
    min-width: 400px;
    display: flex;
    flex-direction: column
}

.ordersummary {
    margin-left: 30px;
    min-width: 300px;
    margin-right: 200px;
    display: flex;
    flex-direction: column
}

.mainlvl {
    margin-top: 10px;
    font-family: sans-serif;
    font-weight: bold;
    color: teal;
}

.addresslocation {
    background-color: white;
}

.AddressDetails {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.addressdheader {
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.lbldvd {
    padding: 10px;
    border-bottom: 1.5px solid lightgray;
}

.yourorderdiv {
    padding: 10px;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
}

.yourorderdivlabe {
    border-bottom: 1.5px solid lightgray;
}

.yourorderitemsdiv {
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow-y: scroll;
    margin-top: 10px;
}

.Completepaymentcomp {
    display: flex;
    flex-direction: row;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

.modifybtndiv {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modifybtndiv button {
    outline: none;
    border: none;
    background-color: white;
    color: grey;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
}

.modifybtndiv button:hover {
    border: 1px solid grey;
}

.yourordersub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

.yourordersub label {
    color: grey;
}

.delivery {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.chekotbtn {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin: 4px;
    border-radius: 7px;
}

.chekotbtn:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04)
}

.selectloc {
    padding: 10px;
    margin: 10px;
    outline: none;
    border-radius: 5px;
}
.Completepaymentcomp {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.yourorderimg {
    object-fit: contain;
    width: 50px;
    height: 100px;
}
.itemsdivcart3{
    
    padding:10px;
    display:flex;
    flex-direction: column;
  
}
.Headerb {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 100000000;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.toppart {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.logosearch {
  padding-left: 100px;
  display: flex;
  margin-left: 2px;
  margin-right: 2px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.logob {
  object-fit: contain;
  width: 140px;
  height: 140px;
  margin-right: 30px;
  cursor: pointer;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.welhead {
  margin-left: 100px;
}

.searchTerm {
  width: 100%;
  border: 2px solid #fed700;
  border-right: none;
  padding: 10px;
  height: 20px;
  font-size: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  outline: none;
}

.searchButton {
  width: 40px;
  height: 44px;
  border: 1px solid #fed700;
  background: #fed700;
  text-align: center;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/

.wrap {
  width: 60%;
}
@media (max-width: 756px) {
    .toppart {
        display: flex;
        padding-top: 10px;
        margin-left: 10px;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
      }

      .logob {
        object-fit: contain;
        width: 70px;
        height: 70px;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
      }
      .wrap {
        width: 100x;
      }
      .welhead {
        margin-left: 5px;
        font-size: 10px;
      }
      .welheadu{
        font-size: 10px;
      }

      
      .logosearch {
        padding-left: 1px;
        display: flex;
        margin-left: 2px;
        margin-right: 2px;
        flex-direction: column;
        margin-left: 1px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
      }
}

.header_bts {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    height:40px
}

.departpart {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    margin-left: 100px;
    background: #FED700;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.departpart label {
    margin-left: 10px;
}

@media screen and (max-width:756px) {
    
    .labelHeader{
        font-size: 9px;
    }
    
}

.header_bts {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
}

.departpart {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    margin-left: 100px;
    background: #FED700;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.departpart label {
    margin-left: 10px;
}
@media screen and (max-width:756px) {
    .departpart{
        display: none;
    }
    .labelHeader{
        font-size: 12px;
    }
    
}
.sliderb {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
}
.sliderMobile {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin-bottom: -550px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.imageb {
    width: 100%;
    height: 500px;
    z-index: -1;
    /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
}


.right-arrowb {
    position: absolute;
    right: 10px;
    margin-top: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 32;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}
.right-arrowMobile {
    position: absolute;
    right: 10px;
    margin-top: 200px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}

.left-arrowb {
    position: absolute;
    margin-top: 200px;
    left: 1px;
    font-size: 3rem;
    color: #000;
    z-index: 32;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}
.left-arrowMobile {
    position: absolute;
    margin-top: 100px;
    left: 1px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}

.slideb {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.activeb {
    opacity: 1;
    transition-duration: 1s;
    -webkit-transform: scale(1.00);
            transform: scale(1.00);
}
.cmaindiv {
    display: flex;
    flex-direction: row;
    padding: 5px;
    background: white;
    border-top: 2px solid #FED700;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ediv {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    z-index: 2000000;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.ediv label {
    cursor: pointer;
}

.cdivLef {
    padding: 10px;
}



.ediv2 {
    padding-left: 50px;
    padding-top: 15px;
    padding-right: 30px;
    align-items: center;
    width:100%;
    cursor: pointer;
}

.ediv2 label {
    cursor: pointer;
}
.ediv2 label:hover{
    width: 100%;
    border-bottom: 1px solid lightgray;
}
.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
  
    height: 400px;
    width: 100%;
    border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }
  .sliderspart{
    position: relative;
    margin-bottom: 5%;
  }
  .innerdiv{
    position: absolute;
    top: 1px;
    left: 100px;
  }
  .productList{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width:756px) {
    .innerdiv{
      display: none;
    }
    
  }
 
.headerMob {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 100000000;
    width: 100%;
    height:100%;
    font-family: 'Open Sans', sans-serif;
}
.searchTermmob {
  width: 100%;
  border: 2px solid  #FED700;
  border-right: none;
  padding: 10px;
  height: 11px;
  font-size: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  outline: none;
 
}
.toppartmob {
    display: flex;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    flex-direction: row;
    align-items:left;
    border-bottom: 1px solid lightgray;
}
.logosearchmob{
    margin-top: 2px;
    display: flex;
    width:100%;
    justify-content:space-evenly;
    flex-direction:row;
    align-items:center;
    margin-bottom: 4px;

}
.logobmob{
    object-fit: contain;
    width:80px;
    height:80px;
    cursor: pointer;
}
.imgMob{
    height:55px;
    width:55px;
    cursor: pointer;
}


.searchmob {
  width: 100%;
  position: relative;
  display: flex;
}
.welheadmob{
  margin-left: 100px;
}



.searchButtonmob {
  width: 40px;
  height: 35px;
  border: 1px solid  #FED700;
  background: #FED700;
  text-align: center;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}

.wrapmob{
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.logoandsearchmob{
    display: flex;
    justify-content:space-around;
    flex-direction:row;
    width:100%;
}
.mobcartsize{
  color:black;
}
.sliderimg{
    width: 100%;
    height: 50%;
    object-fit:contain;
  }
  .mobileSliders{
      background-color:white;
      margin-bottom: -114px;
  }
.modproductList{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content:space-evenly;

}
#product {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.cardm {
    min-width: 150px;
    min-height: 200px;
    overflow: hidden;
    padding: 10px;
    margin: 10px;
    transition: 0.5s linear;
}

.cardm:hover {
    box-shadow: none;
}

.cardm img {
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.cardm h6 {
    text-transform: uppercase;
    margin: 10px 0;
    width: 200px;
}

.cardm h6 a {
    text-decoration: none;
    color: #333;
}

.cardm h3 a:hover {
    color: crimson;
}

.cardm span {
    color: crimson;
}

.cardm p {
    margin: 10px 0;
}

.cardm button {
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
    border-radius: 19px;
}

.search-loadingm {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.showm {
    display: inline-block;
}

.hide {
    display: none;
}

.homem {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.sliderem {
    width: 100%;
}

.home__row {
    display: flex;
    z-index: 1;
    margin-left: 2px;
    margin-right: 2px;
}

.home__image {
    width: 100%;
    z-index: -1;
    margin-bottom: -300px;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.product_pm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2px;
    padding: 10px;
    background-color: white;
    z-index: 1;
}

.product_p_imgpm {
    width: 100%;
    align-items: center;
    object-fit: contain;
}

.product__infopm {
    border-radius: 15px;
    border: 1px solid lightgray;
    position: relative;
    width: 47%;
    background-color: white;
}
.imgmobdiv{
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
}
.product_p_btnpm {
    border: 1px solid;
    margin-top: 10px;
    /* border-color: #a88734 #9c7e31 #846a29; */
    color: #111;
}

.product_ratingpm {
    display: flex;
}

.product_p_btnpm:hover {
    cursor: pointer;
}

.addButtonm {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    outline: none;
    border: none
}

.addButtonm:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    outline: none;
}

.product_pm:hover {
    cursor: pointer;
}

.promotionsdivm {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    z-index: 100;
}

.promolblm {
    color: grey;
    text-decoration: line-through;
}

.addToCartandViewm {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.mainp{
    display:flex;
    padding: 20px;
    background-color:white;
    justify-content:center;
    align-items:center;
}
.mainp{
    display:flex;
    padding: 20px;
    background-color:white;
    justify-content:center;
    align-items:center;
}
