@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.Headerb {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100000000;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.toppart {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.logosearch {
  padding-left: 100px;
  display: flex;
  margin-left: 2px;
  margin-right: 2px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.logob {
  object-fit: contain;
  width: 140px;
  height: 140px;
  margin-right: 30px;
  cursor: pointer;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.welhead {
  margin-left: 100px;
}

.searchTerm {
  width: 100%;
  border: 2px solid #fed700;
  border-right: none;
  padding: 10px;
  height: 20px;
  font-size: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  outline: none;
}

.searchButton {
  width: 40px;
  height: 44px;
  border: 1px solid #fed700;
  background: #fed700;
  text-align: center;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/

.wrap {
  width: 60%;
}
@media (max-width: 756px) {
    .toppart {
        display: flex;
        padding-top: 10px;
        margin-left: 10px;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
      }

      .logob {
        object-fit: contain;
        width: 70px;
        height: 70px;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
      }
      .wrap {
        width: 100x;
      }
      .welhead {
        margin-left: 5px;
        font-size: 10px;
      }
      .welheadu{
        font-size: 10px;
      }

      
      .logosearch {
        padding-left: 1px;
        display: flex;
        margin-left: 2px;
        margin-right: 2px;
        flex-direction: column;
        margin-left: 1px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
      }
}
