.itemGroups {
    display: column;
    border-right: 1px solid lightgray;
    margin-left: 2px;
    padding: 3px;
   
}

.each_group {
    color:rgb(1, 1, 12);
    font-size: 18px;
}

.each_group a:hover {
    color: teal;
    cursor: pointer;
    transform: scale(0.9);
    display: column;
}

.each_sub {
    margin-left: 10px;
    font-size: 13px;
    font-style: bold;
    color:gray;
}