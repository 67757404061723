.search-results {
    background: #F2F4F4;
    padding: 10px;
    width: 100%;
}

.headerdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #85929E;
    padding: 10px;
}

.items-div {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 12px;
    margin-right: 100px;
    margin-left: 10px;
    padding: 10px;
}

.search_page {
    display: flex;
    flex-direction: row;
}

.categoryPanel {
    width: 400px;
    min-width: 300px;
    background-color: white;
    border-radius: 12px;
    margin-left: 30px;
    margin-top: 10px;
    margin-right: 10px;
}

.categoryPanelHeader {
    font-size: 18px;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont;
    margin-top: 40px;
    padding: 8px 16px;
    color: #282828;
}

.panelItems {
    display: flex;
    flex-direction: column;
}

.panelItemsLabel {
    padding: 8px 32px;
    font-size: 15px;
}

.divCatItm {
    width: 100%;
    height: fit-content;
    padding: 10px;
}

.divCatItm:hover {
    background-color: #F2F4F4;
}

.pag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
@media screen  and (max-width:756px) {
    .categoryPanel{
        display: none;
        margin: 1px;
    }
    .items-div {
        margin-top: 10px;
        width: 100%;
        background-color: white;
        border-radius: 12px;
        margin-right: 5px;
        margin-left: 5px;
        padding: 1px;
    }
    .divCatItm {
        width: 100%;
        height: fit-content;
        padding: 1px;
    }
    
}