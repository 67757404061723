.product_categories {
    display: flex;
    flex-direction: row;
    align-items: top;
    width: 100%;
    margin-top: 30px;
    background-color: #F4F6F6;
}

.mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #85929E;
    padding: 10px;
    background-color: #F4F6F6;
}

.product-categoriesLeft {
    width: 200px;
    border-right: 2px solid #CBCFD3;
    padding: 2px;
    height: fit-content;
}

.product-categoriesRight {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.product-the-header {
    width: 100%;
    padding: 5px;
}

.product-category-group {
    color: #0C2134;
    font-size: 18px;
    font-family: 'Varela Round';
}

.divLine {
    width: 100%;
    margin-bottom: 5px;
}

.product-category-group:hover {
    color: #CD6155;
    cursor: pointer;
}

.product-category-subgrouplabel {
    margin-left: 30px;
}

.productC {
    background-color: #F4F6F6;
}

.product-category-subgroup {
    display: flex;
    flex-direction: column;
}

.product-category-subgrouplabel:hover {
    color: #CD6155;
    cursor: pointer;
}

.dFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    background-color: #F4F6F6;
}

.collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}

.catediv {
    margin: 5px;
}

.product_categoriesName {
    align-items: center;
    width: 100%;
    justify-content: center;
}

.product_categoriesName label {
    align-items: center;
    justify-content: center;
    color: teal;
    font-size: 27px;
    margin-left: 100px;
}

.gdivh {
    color: teal;
    font-size: 20px;
}

.dropdownButton {
    border-radius: 5px;
    margin: 2px;
    outline: none;
    cursor: pointer;
    border: 1px solid #CBCFD3;
}

.dropdownButton:hover {
    color: blue;
}

.dropdownButtondiv {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdownButtondiv label:hover {
    color: white;
    background-color: blue;
}

.drowpdown {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #F4F6F6;
}

.dropdownlabel {
    font-size: 12px;
    font-weight: bold;
    color: #555555;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.dropdownbuttonItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2000000;
    background: white;
    padding: 12px;
}

.dropdownButton:hover {
    background-color: #F4F6F6;
}

.sub_item.hover {
    background-color: red;
}

.sub_item {
    text-decoration: none;
    color: #555555;
    width: 100%;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    padding: 7px 12px;
    font-size: 12px;
    border-bottom: 1px solid #CBCFD3;
}

.category_brand {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.head_brand_category {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.hbc {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding:10px;
    border-radius: 6px;
  

}

.inputs {
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.input_header {
    width: 200px;
    padding: 2px;
    margin-left: 5px;
    border-radius: 5px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.category_color {
    margin-top: 10px;
    width: 85%;
}

.dropdownbars {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.inputCheckbox {
    font-size: 13px;
    cursor: pointer;
}
.inputCheckbox:hover{
    color:teal;
}
.divPagenate{
    display: flex;
    flex-direction:row-reverse;
    justify-content:right;
    width: 100%;
    
    
}
.rwidget{
    display: flex;
    flex-direction:row-reverse;
    justify-content:right;
}