#product {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.cardm {
    min-width: 150px;
    min-height: 200px;
    overflow: hidden;
    padding: 10px;
    margin: 10px;
    transition: 0.5s linear;
}

.cardm:hover {
    box-shadow: none;
}

.cardm img {
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.cardm h6 {
    text-transform: uppercase;
    margin: 10px 0;
    width: 200px;
}

.cardm h6 a {
    text-decoration: none;
    color: #333;
}

.cardm h3 a:hover {
    color: crimson;
}

.cardm span {
    color: crimson;
}

.cardm p {
    margin: 10px 0;
}

.cardm button {
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
    border-radius: 19px;
}

.search-loadingm {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.showm {
    display: inline-block;
}

.hide {
    display: none;
}

.homem {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.sliderem {
    width: 100%;
}

.home__row {
    display: flex;
    z-index: 1;
    margin-left: 2px;
    margin-right: 2px;
}

.home__image {
    width: 100%;
    z-index: -1;
    margin-bottom: -300px;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.product_pm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2px;
    padding: 10px;
    background-color: white;
    z-index: 1;
}

.product_p_imgpm {
    width: 100%;
    align-items: center;
    object-fit: contain;
}

.product__infopm {
    border-radius: 15px;
    border: 1px solid lightgray;
    position: relative;
    width: 47%;
    background-color: white;
}
.imgmobdiv{
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
}
.product_p_btnpm {
    border: 1px solid;
    margin-top: 10px;
    /* border-color: #a88734 #9c7e31 #846a29; */
    color: #111;
}

.product_ratingpm {
    display: flex;
}

.product_p_btnpm:hover {
    cursor: pointer;
}

.addButtonm {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    outline: none;
    border: none
}

.addButtonm:hover {
    transform: scale(1.05);
    outline: none;
}

.product_pm:hover {
    cursor: pointer;
}

.promotionsdivm {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    z-index: 100;
}

.promolblm {
    color: grey;
    text-decoration: line-through;
}

.addToCartandViewm {
    display: flex;
    flex-direction: row;
    justify-content: center;
}