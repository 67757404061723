.navbar {
    display: flex;
    align-items: center;
    margin: 10px;
    z-index: 200;
    background: white;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
}

.menubar {
    font-size: 20px;
}
.nav-menu-items{
    background: white;
}
.nav-menu {
    background: #00000050;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 150ms;
    z-index: 200;
}

.nav-menu.active {
    left: 0;
    transition: 150ms;
}

.nav-text {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: 10px;
}

.nav-text a {
    text-decoration: none;
    font-size: 15px;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: white;
}

.nav-text a:hover {
    background-color: teal;
}

.navbar-toggle {
    text-decoration: none;
    display: flex;
    background: white;
}

.closeIcon {
    color: red;
    font-size: 30px;
    margin-left: 70px;
}