.maincartdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
}

.prdimgCss {
    object-fit: contain;
    width: 250px;
    height: 300px;
}

.imganddetails {
    display: flex;
    flex-direction: row;
}

.detailspro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px
}

.blayout {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.divQuantity {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid lightgray;
}

.divDelete {
    padding-right: 10px;
    margin-right: 10px;
}

.lbldelete {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

.lbldelete:hover {
    color: teal;
    cursor: pointer;
    text-decoration: underline;
}

.lblh {
    margin-top: 20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.lblh:hover {
    color: teal;
    cursor: pointer;
    text-decoration: underline;
}

.llblprice {
    margin-top: 30px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.checkoutqtinp {
    outline: none;
    padding: 4px;
    align-items: center;
    width: 80px;
    border-radius: 8px;
    border: 1px solid lightgray;
    font-size: 14px;
}

.itemprelisquits {
    display: flex;
    flex-direction: column;
}

.rediced {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.lvlh {
    font-size: 13px;
    color: teal;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.lvlhsub {
    font-size: 13px;
    color: teal;
    font-family: Arial, Helvetica, sans-serif;
}

.divsright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.amountvalue {
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.lblamountvalue{
    font-size:24px;
    color:teal;
}