.login {
    display: flex;
    height: 100vh;
    background-color: white;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
}
.login__container{
    width:300px;
    height:fit-content;
    display:flex;
    flex-direction: column;
    border-radius: 5px;
    border:1px solid lightgray;
    padding:20px
}
.login__container >h1{
    font-weight: 500;
}
.login__container form >input{
    height :30px;
    margin-bottom: 10px;
    width:98%;
    background-color:white;
}
.login__container >p{
    margin-top:15px;
    font-size:12px;
}
.login__signButton{
    background:#f0c14b;
    border-radius: 2px;
    width:100%;
    height:30px;
    border:1px solid;
    margin-top:10px;
    border-color :#a88734 #9c7e31 #846a29;
}
.login__registerButton{
     border-radius: 2px;
     width: 100%;
     height:30px;
     border:ipx solid;
     margin-top:10px;
     border-color:darkgray;
}

