.cmaindiv {
    display: flex;
    flex-direction: row;
    padding: 5px;
    background: white;
    border-top: 2px solid #FED700;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ediv {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    z-index: 2000000;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.ediv label {
    cursor: pointer;
}

.cdivLef {
    padding: 10px;
}



.ediv2 {
    padding-left: 50px;
    padding-top: 15px;
    padding-right: 30px;
    align-items: center;
    width:100%;
    cursor: pointer;
}

.ediv2 label {
    cursor: pointer;
}
.ediv2 label:hover{
    width: 100%;
    border-bottom: 1px solid lightgray;
}