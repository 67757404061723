.shoppingmainpage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
}

.shoppingdetails {
    margin: 20px;
    padding:20px;
    background-color: white;
    width:100%;
    min-width: 800px;
}

.cartdetails {
    min-width: 300px;
  
 
  
}
.shoppingcartpart{
    height: max-content;
    margin: 10px;
    padding:10px;
    background-color: white;
}

.shoppingheaderlabel {
    font-size: 28px;
    color:#0F1111;
    font-family: Arial, Helvetica, sans-serif;
}
.hpartprice{
    align-items:left;
    display:flex;
    flex-direction:row-reverse;
    border-bottom: 1px solid  #F2F4F4 ;
}
.lprc{
    color:#565959;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 4px;
}
.subtotalspan{
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}
.check_btn{
    padding:10px;
    margin-top:10px;
    width:100%;
    border:none;
    outline:none;
    border-radius:10px;
    margin-bottom: 20px;
}
.check_btn:hover{
    border:none;
    cursor: pointer;
    transform: scale(1.05)
}
.botmpart{
    display: flex;
    flex-direction: row-reverse;
}
.otheritemspart{
    height: max-content;
    margin: 10px;
    padding:10px;
    background-color: white;
    border-radius: 12px;
}
.otherpartlblheader{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #0f1111;
}