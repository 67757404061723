.details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 0;
}

.productWhole {
    width: 100%;
    background: white;
}

.details .big-img {
    max-width: 500px;
    min-width: 290px;
    overflow: hidden;
    margin: 25px;
}

.big-img img {
    width: 100%;
    height: 100%;
    max-height: 450px;
    display: block;
    object-fit: contain;
}

.about_title {
    font-size: 24px;
    font-style: bold;
}

.details .box {
    max-width: 500px;
    min-width: 290px;
    margin: 25px;
}

.box .row {
    display: flex;
    justify-content: space-between;
}
.box{
    position: relative;
}

.box .row span {
    text-transform: uppercase;
    color: #0F1111;
    font-size: 24px;
    ;
    font-family: "Amazon Ember", "Arial", sans-serif;
}

.box .colors button {
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
}

.box p {
    line-height: 1.5;
    margin: 15px 0;
}

.thumb {
    width: 100%;
    height: 100px;
    display: flex;
    cursor: pointer;
    margin: 10px 0;
}

.thumb img {
    width: 65px;
    height: 70%;
    display: contain;
    object-fit: contain;
    border: 1px solid #ddd;
    margin-right: 5px;
    opacity: 0.7;
    border-radius: 5px;
}

.thumb img.active {
    opacity: 1;
    border: 1px solid lightseagreen;
}

.box .cart {
    background: #333;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 25px;
    margin-top: 15px;
}

.search-loading {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.show {
    display: inline-block;
}

.hide {
    display: none;
}

@media (max-width: 500px) {
    .thumb {
        height: 50px;
    }
    .thumb img {
        width: 50px;
    }
}

.item_group {
    font-size: 14px;
    padding: 2px 5px 2px 6px;
    font-family: "Amazon Ember", "Arial", sans-serif;
}

.item_grouplabel {
    font-size: 24px;
    font-weight: bold;
    padding: 2px 5px 2px 6px;
    font-family: "Amazon Ember", "Arial", sans-serif;
}

.groupcodelabel {
    font-size: 10px;
    font-weight: bold;
    padding: 2px 5px 2px 6px;
    font-family: "Amazon Ember", "Arial", sans-serif;
    margin-left: 5px;
}
.promotionsdiv {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display:flex;
    flex-direction: row;
    justify-content:center;
    border-radius: 8px;
    z-index: 100;
}
.promolbl{
    color:grey;
    text-decoration: line-through;
}
.pricespans{
    display:flex;
    flex-direction:column;

}