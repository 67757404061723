.dFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.lb {
    color: black;
}

.card_img1 img {
    width: 200px;
    height: 100px;
    object-fit: contain;
}

.card_header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px;
}

.card_header h2 {
    font-size: 15px;
    
}

.card_header p {
    font-size: 15px;
}

.promotionsdiv {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    z-index: 1;
}

.promolbl {
    color: grey;
    text-decoration: line-through;
}

.btncardadd {
    outline: none;
    border: 1px solid lightgray;
    background-color: transparent;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: teal;
    margin:2px;
}

.btncardadd:hover {
    transform: scale(1.05)
}

.addToCartandView {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media screen and (max-width:756px) {
.card{
    width: 1px;
    height: 30px;
}
.btncardadd {
    outline: none;
    border: 1px solid lightgray;
    background-color: transparent;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: teal;
    margin:0px;
    font-size: 12px;
}

.card_header h2 {
    font-size: 13px;
    
}
}