.sliderb {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
}
.sliderMobile {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    margin-bottom: -550px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.imageb {
    width: 100%;
    height: 500px;
    z-index: -1;
    /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
}


.right-arrowb {
    position: absolute;
    right: 10px;
    margin-top: 100px;
    font-size: 3rem;
    color: #000;
    z-index: 32;
    cursor: pointer;
    user-select: none;
    
}
.right-arrowMobile {
    position: absolute;
    right: 10px;
    margin-top: 200px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    
}

.left-arrowb {
    position: absolute;
    margin-top: 200px;
    left: 1px;
    font-size: 3rem;
    color: #000;
    z-index: 32;
    cursor: pointer;
    user-select: none;
    
}
.left-arrowMobile {
    position: absolute;
    margin-top: 100px;
    left: 1px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    
}

.slideb {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.activeb {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.00);
}