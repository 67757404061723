.navbarside {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 1000000000;
}

.navbar_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header_part {
    display: flex;
    top: 0;
    flex-direction: row;
    position: sticky;
    width: 350px;
    justify-content: space-between;
    background: red;
}

.header_partlable {
    font-weight: bold;
    padding: 10px;
    font-size: 15px;
    margin: 2px;
    color: white;
}

.header_avatar {
    margin-left: 20px;
}

.x_icon {
    width: 65px;
    height: 65px;
    color: white;
    font-weight: bold;
    font-size: 30px;
}

.navbar_right {
    justify-content: center;
    align-items: top;
    width: 100%;
}

.cat_below_header {
    width: 100%;
    height: 86vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    padding-bottom: 40px;
}

.cat_below_header .label {
    font-size: 14px;
}

.cat_item {
    margin-left: 20px;
    display: flex;
    margin-top: 2px;
    border-radius: 10px;
    padding: 9px;
    width: 80%;
    justify-content: space-between;
}

.cat_item:hover {
    background-color: #EBEDEF;
    cursor: pointer;
}

.cat_back {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 10px;
    width: 100%;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.cat_back label {
    margin-left: 10px;
}

.cat_back:hover {
    background-color: #EBEDEF;
}