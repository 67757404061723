@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.headerMob {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100000000;
    width: 100%;
    height:100%;
    font-family: 'Open Sans', sans-serif;
}
.searchTermmob {
  width: 100%;
  border: 2px solid  #FED700;
  border-right: none;
  padding: 10px;
  height: 11px;
  font-size: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  outline: none;
 
}
.toppartmob {
    display: flex;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    flex-direction: row;
    align-items:left;
    border-bottom: 1px solid lightgray;
}
.logosearchmob{
    margin-top: 2px;
    display: flex;
    width:100%;
    justify-content:space-evenly;
    flex-direction:row;
    align-items:center;
    margin-bottom: 4px;

}
.logobmob{
    object-fit: contain;
    width:80px;
    height:80px;
    cursor: pointer;
}
.imgMob{
    height:55px;
    width:55px;
    cursor: pointer;
}


.searchmob {
  width: 100%;
  position: relative;
  display: flex;
}
.welheadmob{
  margin-left: 100px;
}



.searchButtonmob {
  width: 40px;
  height: 35px;
  border: 1px solid  #FED700;
  background: #FED700;
  text-align: center;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}

.wrapmob{
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.logoandsearchmob{
    display: flex;
    justify-content:space-around;
    flex-direction:row;
    width:100%;
}
.mobcartsize{
  color:black;
}