.header_bts {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
}

.departpart {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    margin-left: 100px;
    background: #FED700;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.departpart label {
    margin-left: 10px;
}
@media screen and (max-width:756px) {
    .departpart{
        display: none;
    }
    .labelHeader{
        font-size: 12px;
    }
    
}