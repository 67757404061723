.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
  
    height: 400px;
    width: 100%;
    border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }
  .sliderspart{
    position: relative;
    margin-bottom: 5%;
  }
  .innerdiv{
    position: absolute;
    top: 1px;
    left: 100px;
  }
  .productList{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width:756px) {
    .innerdiv{
      display: none;
    }
    
  }
 