.carousel_header {
    width: 100%;
    height: 200px;
    background: #333;
    color: #fff;
    font-size: 30px;
    margin: 0 20;
    display: flex;
  
    justify-content: center;
    align-items: center;
}

.item_header {
    margin-left: 20px;
    color: grey;
    margin-right: 10px;
}

.itemGroupMain {
    background: white;
    border-radius: 14px;
    margin: 10px;
   
}

.product_p_c {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1px;
    margin:20px;
    background-color: white;
    z-index: 1;
}

.group_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group_header a {
    color: blue;
    text-decoration: none;
    margin-right: 20px;
}

.group_header a:hover {
    color: teal;
   
}
