header {
    min-height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

header .logo a {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
}

header nav {
    display: flex;
}

nav a:hover {
    color: lightseagreen;
}

nav ul li {
    list-style: none;
    display: inline-block;
}

nav ul li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #555;
    padding: 0 15px;
}

nav ul li a:hover {
    color: lightseagreen;
}

nav .nav-cart {
    cursor: pointer;
    position: relative;
    margin: 10px;
}

nav .nav-cart span {
    position: absolute;
    top: -12px;
    right: -7px;
    background: crimson;
    font-size: 10px;
    color: white;
    padding: 3px 5px;
    border-radius: 50%;
    z-index: -1;
}

header .menu, header .close {
    cursor: pointer;
    display: none;
}

@media (max-width: 650px) {
    header ul {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        background: white;
        z-index: 99;
        opacity: 0.97;
        transition: 0.5s linear;
    }
    header .menu, header .close {
        display: block;
    }
    header .close {
        position: absolute;
        top: 10px;
        right: 20px;
    }
    header ul.toggle {
        left: 0;
    }
  
    header.grid a:hover {
        color: lightseagreen;
    }
}