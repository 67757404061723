       #product {
           width: 100%;
           display: flex;
           justify-content: left;
           flex-wrap: wrap;
       }

       .card {
           min-width: 150px;
           min-height: 200px;
           overflow: hidden;
           padding: 10px;
           margin: 10px;
           transition: 0.5s linear;
       }

       .card:hover {
           box-shadow: none;
       }

       .card img {
           max-height: 300px;
           display: block;
           object-fit: cover;
       }

       .card h6 {
           text-transform: uppercase;
           margin: 10px 0;
           width: 200px;
       }

       .card h6 a {
           text-decoration: none;
           color: #333;
       }

       .card h3 a:hover {
           color: crimson;
       }

       .card span {
           color: crimson;
       }

       .card p {
           margin: 10px 0;
       }

       .card button {
           border: none;
           outline: none;
           background: #333;
           color: white;
           width: 100%;
           height: 40px;
           display: block;
           cursor: pointer;
           text-transform: uppercase;
           letter-spacing: 2px;
           margin: 15px 0;
           border-radius: 19px;
       }

       .search-loading {
           position: absolute;
           left: 0;
           right: 0;
           margin: auto;
       }

       .show {
           display: inline-block;
       }

       .hide {
           display: none;
       }

       .home {
           display: flex;
           justify-content: center;
           margin-left: auto;
           margin-right: auto;
       }

       .slidere {
           width: 100%;
       }

       .home__row {
           display: flex;
           z-index: 1;
           margin-left: 2px;
           margin-right: 2px;
       }

       .home__image {
           width: 100%;
           z-index: -1;
           margin-bottom: -300px;
           mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
       }

       .product_p {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           margin: 2px;
           padding: 10px;
           background-color: white;
           z-index: 1;
       }

       .product_p_imgp {
           max-height: 250px;
           width: 100%;
           max-width: 270px;
           object-fit: contain;
       }

       .product__infop {
           padding: 15px;
           border-radius: 15px;
           position: relative;
       }

       .product_p_btnp {
           border: 1px solid;
           margin-top: 10px;
           /* border-color: #a88734 #9c7e31 #846a29; */
           color: #111;
       }

       .product_ratingp {
           display: flex;
       }

       .product_p_btnp:hover {
           cursor: pointer;
       }

       .addButton {
           align-items: center;
           justify-content: center;
           display: flex;
           flex-direction: row;
           cursor: pointer;
           outline: none;
           border: none
       }

       .addButton:hover {
           transform: scale(1.05);
           outline: none;
       }

       .product_p:hover {
           cursor: pointer;
       }

       .promotionsdiv {
           width: 58px;
           position: absolute;
           top: 0px;
           right: 0px;
           display:flex;
           flex-direction: row;
           justify-content:center;
           border-radius: 8px;
           z-index: 100;
       }
      .promotionsdivl{
        width: 100px;
        position: absolute;
        top: 0px;
        left: 0px;
        display:flex;
        flex-direction: row;
        justify-content:center;
        border-radius: 8px;
        z-index: 100;
      }
       .promolbl{
           color:grey;
           text-decoration: line-through;
       }