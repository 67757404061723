.header {
    height: fit-content;
    display: flex;
    align-items: center;
    background-color: #131921;
    position: sticky;
    top: 0;
    z-index: 100;
}

.navbarIcon {
    color: white;
    margin-left: 20px;
    align-self: center;
    z-index: 90000000000;
    justify-content: center;
    width: 50px;
}

.topHeader {
    display: flex;
    background-color: #131921;
    align-items: center;
    justify-content: center;
}

.on__header {
    position: sticky;
    top: 0;
    background-color: #131921;
    z-index: 100;
}

.header__logo {
    width: 100px;
    height: 50px;
    object-fit: contain;
    margin: 0 20px;
}

.header__logo1 {
    width: 100px;
    height: 50px;
    object-fit: contain;
    margin: 10px 20px;
    align-items: center;
}

.header__searchIcon {
    padding: 5px;
    margin-top: 3px;
    height: 22px !important;
    background-color: #cd9042;
    align-items: center;
}

.header__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
}

.header__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    padding: 2px;
}

.header_searchInput {
    height: 12px;
    min-width: 100px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header_nav {
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
}

.header__optionLineOne {
    font-size: 10px;
}

.header__optionLineTwo {
    font-size: 13px;
    font-weight: 800;
}

.header_optionBasket {
    display: flex;
    align-items: center;
    color: white;
}

.header_basketCount {
    margin-left: 10px;
    margin-right: 10px;
}

.header__preliminaries {
    background-color: #131921;
    padding: 10px;
}

.header__button {
    font-size: 13px;
    font-weight: 800;
    color: white;
    background-color: teal;
}

.header__combobox {
    padding: 10px;
    background-color: white;
    color: orange;
}

.header__select {
    padding: 6.8px 14px;
    align-items: center;
    color: #333333;
    background-color: #eeeeee;
    border: 1px solid #dddddd;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 10px;
}

.header__select:focus, .header__select:hover {
    outline: none;
    border: 1px solid #bbbbbb;
}

.header__option .header__optionLineTwo:hover {
    color: yellow;
}

.header_nav .header__option:hover {
    border: 1px solid #bbbbbb;
}

.menu-bar {
    color: white;
    margin-left: 10px;
    font-size: 15px;
}

.titlep {
    font-weight: bold;
    margin-left: 4px;
}

.subdisplay {
    display: flex;
    flex-direction: column;
}

.sublabeldisplay {
    color: teal;
    cursor: pointer;
}

.sublabeldisplay:hover {
    color: red;
}

.gorgGroupName {
    cursor: pointer;
}

.gorgGroupName :hover {
    color: red;
}

.nav-menu-items {
    display: flex;
    flex-wrap: wrap;
}

.labelHeader {
    padding: 5px 0 6px;
    cursor: pointer;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 3px;
    text-decoration: none;
    font-size: 15px;
    font-family: 'Arial', sans-serif;
}

.labelHeader:hover {
    border: 2px solid white;
}
.header_btm{
    height:40px;
}