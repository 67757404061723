.Categories {
    background-color: rgb(234, 237, 237);
}

.categories_title {
    margin-right: 10px;
    position: sticky;
}

#product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    justify-content:center;
}

.Categories_left {
    width: 100%;
}

.Categories_left1 {
    margin-left: 250px;
    width: 100%;
}

.each_sub_c {
    cursor: pointer;
    color: teal;
    font-size: 13px;
    margin-left: 6px;
}

.card {
    min-width: 150px;
    min-height: 200px;
    overflow: hidden;
    padding: 10px;
    margin: 10px;
    transition: 0.5s linear;
}

.card:hover {
    box-shadow: none;
}

.card img {
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.card h6 {
    text-transform: uppercase;
    margin: 10px 0;
    width: 200px;
}

.sub {
    display: flex;
    object-fit: contain;
    position: relative;
}

.sub p {
    margin-left: 10px;
    margin-bottom: 10px;
}

.main_categories label:hover {
    color: red;
}

.sub label:hover {
    color: red;
}

.card h6 a {
    text-decoration: none;
    color: #333;
}

.card h3 a:hover {
    color: crimson;
}

.card span {
    color: crimson;
}

.card p {
    margin: 10px 0;
}

.card button {
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
    border-radius: 19px;
}

.search-loading {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.show {
    display: inline-block;
}

.hide {
    display: none;
}

.home {
    display: flex;
}

.home__row {
    display: flex;
    z-index: 1;
    margin-left: 2px;
    margin-right: 2px;
}

.home__image {
    width: 100%;
    z-index: -1;
    margin-bottom: -300px;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.product_ps {
    display: flex;
    flex-direction: column;
    margin: 2px;
    padding: 10px;
    background-color: white;
}

.product_p_img {
    max-height: 150px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 15px;
}

.product__info {
    margin-bottom: 15px;
}

.product_p_btn {
    background: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    width: 100%;
}

.product_rating {
    display: flex;
}

.each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.item_group_name {
    justify-content: center;
    align-items: left;
    font-size: 40px;
    font-style: bold;
    margin-left: 20px;
}

.main_categories {
    padding: 10px;
}

.main_category_header {
    font-size: 20px;
    margin: 6px;
    color: teal;
    cursor: pointer;
}

.main_category_header:hover {
    color: red;
}

.popupcategories {
    margin-left: 20px;
}

.ll {
    font-weight: bold;
}

.categoriesinput {
    width: 80%;
    padding: 2px;
    position: sticky;
    top: 0;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
}

.buttonCategories {
    border-radius: 4px;
    background-color: teal;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    width: 98%;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

.buttonCategories span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.buttonCategories span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.buttonCategories:hover span {
    padding-right: 25px;
}

.buttonCategories:hover span:after {
    opacity: 1;
    right: 0;
}
@media screen and (max-width:756px) {
    .item_group_name{
        font-size: 18px;
    }
    
}