.aboutus {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
}

.aboutusimgbanner {
    object-fit: contain;
    height: 60px;
}