.login3 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.login__container2 {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid lightgray;
}

.login__container2>h1 {
    font-weight: 400;
}

.smallabel {
    height: 10px;
    font-size: 13px;
}

.login__container2 form>input {
    width: 98%;
    background-color: white;
    height: 30px;
    border: 1px solid lightgray;
}

.login__container2>p {
    font-size: 12px;
}

.login__signUpButton {
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
}

.login__registerButton {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: ipx solid;
    margin-top: 10px;
    border-color: darkgray;
}

.field {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.signinlink {
    margin-left: 10px;
    text-decoration: none;

}

.signinlink:hover {
    background-color: rgba(255, 255, 255, 0.45);
}

.field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
    padding: 24px 16px 8px 16px;
}

.field.active input+label {
    top: 4px;
    opacity: 1;
    color: #512da8;
}

.field.locked {
    pointer-events: none;
}

.field input {
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.field input+label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
}

.field input+label.error {
    color: #ec392f;
}

.field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #e0e0e0;
    opacity: 1;
    pointer-events: none;
}