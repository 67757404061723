.caures_card {
    display: flex;
    flex-direction: column;
    position: relative;

}

.caures_img {
    height: 200px;
    width: 250px;
    object-fit: contain;
}

.divcaures {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.lblproductname {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont;
    font-size: 16px;
    color: #282828;
    padding: 8px 8px 4px;
}

.lblamount {
    padding: 8px 4px 0px;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont;
    font-size: 20px;
    color: #282828;
}

.promotionsdiv {
    width: 58px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    z-index: 100;
}

.promolbl {
    color: grey;
    text-decoration: line-through;
}
.namenprocediv{
    display: flex;
    flex-direction:column;
    justify-content: center;
    width:100%;
    margin-left: 20px;
}