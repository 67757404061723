.checkoutProduct {
    display: flex;
    padding: 10px;
    border: 1px solid lightgray;
    margin: 20px;
    border-radius: 10px;
}

.checkoutProduct__info {
    padding-left: 20px;
    display: flex;
    flex-direction:column;
    justify-content:space-evenly;
}

.checkoutProduct__info>button {
    background: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111
}

.checkoutproduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px
}

.checkoutProduct__title {
  font-family: Roboto, Helvetica, sans-serif;
}

.count {
    width: 30px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #555;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.checkout__input {
    width: 70px;
    text-align: center;
}
.checkoutProduct__price{
    font-family: Roboto, Helvetica, sans-serif; 
    font-size: 18px;
    color:grey;
}