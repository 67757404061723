.each-search-result {
    display: flex;
    border-bottom: 2px solid #F2F4F4;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    margin: 20px;
    position: relative;
}
.each-search-result:hover{
    transform:scale(1.04)
}
.each-search-resultP1 {
    display: flex;
    flex-direction: row;
}

.image1 {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.product-details {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.lblItemName {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}

.lblItemCode {
    color: blue;
    font-size: 16px;
}

.lblPrice {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice small {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice strong {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}

.product-pricing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.btnAddtocart{
    color:#FFFF;
    border:none;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 70px;
    padding-left: 70px;
    min-width: 100px;
    font-size: 18px;

}
.pricesdiv{
    display: flex;
    flex-direction:column;
}
@media screen and (max-width:756px){

   .each-search-result {
    display: flex;
    border-bottom: 2px solid #F2F4F4;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px;
    margin: 3px;
    position: relative;
} 
.btnAddtocart{
    color:#FFFF;
    border:none;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 3px;
    padding-left: 3px;
    min-width: 100%;
    font-size: 13px;

}
.lblPrice {
    margin-top: 10px;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice small {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.lblPrice strong {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.product-details {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.lblItemName {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    color: #282828;
}
.image1 {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.product-details {
    display: flex;
    flex-direction: column;
    margin: 4px;
}
}