.showitems{
    display: flex;
    width:100%;
    flex-direction: column;
    background-color: white;
}
.showitemstop{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding:10px;
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 10px;
}
.arrowbackshow{
    align-items:center;
    margin-right: 20px;
    font-size: 30px;
}
.shitmlbltop{
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 20px;
}
.checkout__tit{
    margin-left: 10px;
    font-family: Roboto, Helvetica, sans-serif;
    padding:4px;
}
.itemsorder{
    margin:4px;
    display: flex;
}
.itemsorderlbl{
    margin-top: 10px;
    font-family: Roboto, Helvetica, sans-serif;  
    padding: 10px;
    font-size: 20px;
}
