#product {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
.productList1{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;  
}
.card {
    min-width: 150px;
    min-height: 200px;
    overflow: hidden;
    padding: 10px;
    margin: 10px;
    transition: 0.5s linear;
}

.card:hover {
    box-shadow: none;
}

.card img {
    max-height: 300px;
    display: block;
    object-fit: cover;
}

.card h6 {
    text-transform: uppercase;
    margin: 10px 0;
    width: 200px;
}

.card h6 a {
    text-decoration: none;
    color: #333;
}

.card h3 a:hover {
    color: crimson;
}

.card span {
    color: crimson;
}

.card p {
    margin: 10px 0;
}

.card button {
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    
    letter-spacing: 2px;
    margin: 15px 0;
    border-radius: 19px;
}

.search-loading {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.show {
    display: inline-block;
    width: 150px;
    background-color: transparent;
}

.hide {
    display: none;
}

.home {
    display: flex;
    justify-content: center;
    margin-right: auto;
}
.slidere{
    width: 100%;
}
.home__row {
    display: flex;
    z-index: 1;
    margin-right: 2px;
}

.home__image {
    width: 100%;
    z-index: -1;
    margin-bottom: -300px;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}



.product_p_img {
    max-height: 150px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 15px;
}

.product__info {
    margin-bottom: 15px;
}


.product_rating {
    display: flex;
}

.each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
}

.paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 10px;
    border: 1px solid teal;
    cursor: pointer;
}

.paginationBttns a:hover {
    color: white;
    background-color:teal;
}

.paginationActive a {
    color: white;
    background-color: teal;
}
.product_p{
   border-radius: 1px;
}

