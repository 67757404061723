.cartsimilarmain {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-bottom: 1px solid lightgray;
}

.cartsimilarimg {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.itemsdivcart {
    margin: 8px;
    display: flex;
    flex-direction: column;
   
}

.cartsimilarname {
    font-size: arial, Helvetica, sans-serif;
    font-size: 14px;
    color: teal;
}

.cartsimilarprice {
    font-size: arial, Helvetica, sans-serif;
    font-size: 16px;
    color: rgb(48, 43, 43);
    margin-top: 4px;
    margin-bottom: 4px;
}

.cartsimalerbutton {
    outline: none;
    border-radius: 4px;
    border: none;
    padding:6px;
    cursor: pointer;
}
.cartsimalerbutton:hover{
    transform: scale(1.06)
}