.Completepaymentcomp {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.yourorderimg {
    object-fit: contain;
    width: 50px;
    height: 100px;
}
.itemsdivcart3{
    
    padding:10px;
    display:flex;
    flex-direction: column;
  
}