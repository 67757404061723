.CompleteMainPage {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.checkoutmain {
    width: 100%;
    margin-left: 200px;
    min-width: 400px;
    display: flex;
    flex-direction: column
}

.ordersummary {
    margin-left: 30px;
    min-width: 300px;
    margin-right: 200px;
    display: flex;
    flex-direction: column
}

.mainlvl {
    margin-top: 10px;
    font-family: sans-serif;
    font-weight: bold;
    color: teal;
}

.addresslocation {
    background-color: white;
}

.AddressDetails {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.addressdheader {
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.lbldvd {
    padding: 10px;
    border-bottom: 1.5px solid lightgray;
}

.yourorderdiv {
    padding: 10px;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
}

.yourorderdivlabe {
    border-bottom: 1.5px solid lightgray;
}

.yourorderitemsdiv {
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow-y: scroll;
    margin-top: 10px;
}

.Completepaymentcomp {
    display: flex;
    flex-direction: row;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

.modifybtndiv {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modifybtndiv button {
    outline: none;
    border: none;
    background-color: white;
    color: grey;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
}

.modifybtndiv button:hover {
    border: 1px solid grey;
}

.yourordersub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

.yourordersub label {
    color: grey;
}

.delivery {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.chekotbtn {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin: 4px;
    border-radius: 7px;
}

.chekotbtn:hover {
    transform: scale(1.04)
}

.selectloc {
    padding: 10px;
    margin: 10px;
    outline: none;
    border-radius: 5px;
}