.categoriesAd {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 2px;
    z-index: 10000;
}

.classLeft {
    width: 100%;
    height: 100vh;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
}

.cat-layout {
    position: sticky;
    background:  white;
    top: 0;
    z-index: 10000;
}



.cat-search {
    margin-left: 2px;
    width: 95%;
    border-radius: 10px;
    padding: 6px;
    border-color: white;
    margin-bottom: 20px;
}

.cat-search:focus {
    border-color: white;
    outline: none;
}

.accordions {
    width: 99%;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
}

.accordionItem {
    width: 99%;
}