.aboutuscompmain {
    display: flex;
    justify-content: center;
    background:white;
    width: 70%;
    margin: 10px;
}

.rightpart {
    width: 100%;
    padding: 10px;
}

.leftpart {
    width: 50%;
    padding: 20px;
}

/* .leftpart p {
    font: normal normal 12px/1.4 "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 19px;
    width:50%;
    align-items:center;
    
} */
.ourimg{
    width:100%;
    object-fit: contain;

}
.tt{
    display: flex;
    flex-direction: column;
    justify-content: center;
}